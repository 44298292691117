<script setup lang="ts">
import defaultUserImg from '~~/public/temp/user.svg';

import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';

const isEmpty = $isEmpty;
const runtime = useRuntimeConfig();
const route = useRoute();
const props = withDefaults(
  defineProps<{
    image?: string | null;
    userId?: number | string | null;
    calledLocation?:
      | 'COMMUNITY_CONTENT'
      | 'COMMENT_RANKING'
      | 'REMARKABLE_MEMBER'
      | 'COMMUNITY_LIST'
      | 'COMMUNITY_COMMENT'
      | 'GROUP_MEMBER' // user-avater 를 요청한 위치, 이벤트 기록을 위하여 추가
      | null;
    size: 'default' | 'small' | 'regular' | 'large' | 'xlarge' | 'xxlarge';
  }>(),
  {
    image: null,
    userId: null,
    calledLocation: null,
    size: 'default',
  },
);

const imageUrl = computed<string | undefined>(() => {
  if (isEmpty(props.image)) {
    return defaultUserImg;
  }
  return Utility.getOptimized(props?.image, 'PROFILE_IMG') ?? undefined;
});

const clickUserProfileEvent = (userId?: number | string | undefined) => {
  if (!props.calledLocation || !userId) {
    return;
  }

  const url = `${runtime.public.frontUrl}${route.fullPath}`;
  const path = route.path;
  Tracker['Click User Profile'](props.calledLocation, { userId, url, path });
};
</script>

<template>
  <NuxtLink
    v-if="!isEmpty(userId)"
    :to="`/profile/${userId}`"
    @click.stop="() => !isEmpty(userId) && clickUserProfileEvent(userId)">
    <Avatar
      :image="imageUrl"
      shape="circle"
      class="flex rounded-full overflow-hidden"
      :class="{
        'small-avatar': size === 'small',
        'regular-avatar': size === 'regular',
        'large-avatar': size === 'large',
        'xlarge-avatar': size === 'xlarge',
        'xxlarge-avatar': size === 'xxlarge',
      }" />
  </NuxtLink>
  <Avatar
    v-else
    :image="imageUrl"
    shape="circle"
    class="rounded-full overflow-hidden"
    :class="{
      'small-avatar': size === 'small',
      'regular-avatar': size === 'regular',
      'large-avatar': size === 'large',
      'xlarge-avatar': size === 'xlarge',
      'xxlarge-avatar': size === 'xxlarge',
    }" />
</template>

<style lang="scss" scoped>
:deep(img) {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.xxlarge-avatar {
  width: 112px;
  height: 112px;
  min-width: 112px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.xlarge-avatar {
  width: 96px;
  height: 96px;
  min-width: 96px;
}
.large-avatar {
  width: 64px;
  height: 64px;
  min-width: 64px;
}
.small-avatar {
  min-width: 40px;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.regular-avatar {
  min-width: 48px;
  width: 48px;
  height: 48px;
}
</style>
